import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (loading) return;

    setLoading(true);
    const payload = {
      rfid: props?.user?.rfid,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/rfid/reentry", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="space-y-5 px-5 py-5 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold">
          <div className="text-xl mt-5">MAIN GATE</div>
        </div>

        <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
          ALLOWED TO RE_CHECKIN
        </div>

        {/* <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
          ALREADY CHECKED IN
        </div> */}

        <div className="flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded">
          <img src={CONFIG.URL_MEDIA + "/" + props?.user?.photo} className="rounded w-full h-full  bject-contain" alt="" />
        </div>
        

        <div className="mx-auto flex justify-center items-center space-x-4">
          <button
            type="button"
            onClick={() => props.ok()}
            className={`w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={() => onSubmit()}
            className={`w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
          >
            {
              loading ? 'Loading...' : 'CONFIRM'
            }
          </button>
        </div>
      </div>
    </>
  );
};

export default FormRFIDResult;
