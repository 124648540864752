import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";

const FormVerification = (props) => {
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const [msg, setMsg] = useState("");
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");
    const payload = {
      id_user: data.name,
      birth_date: data.birth_date,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/login", form_data)
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess({
              ...response.data.data[0],
              token: response.data.token
            });
          } else {
            if (response?.data?.error_code === "user_not_found") {
              setMsg(
                "Birth Date entered does not match user data on file. Please try again."
              );
            } else {
              setMsg("Error: " + response.data?.message);
            }
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-1/2"
      >
        <div className="text-center font-bold mb-5">
          <div className="text-xl mb-4">
            First Citizens End of Year Staff Party
          </div>

          <div className="text-2xl">Book Your Ticket</div>
          <div>Please begin typing your name and select from listing</div>
        </div>

        <div className="flex flex-col">
          <label className="text-black w-full">Name:</label>

          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field: { onChange, value, ref, name } }) => (
              <ReactSelect
                autoFocus
                className={"react-select"}
                classNamePrefix={"react-select"}
                placeholder={"Search your name"}
                options={props.options}
                onInputChange={(val) => {
                  if (val.length === 0) {
                    setOpenMenu(false);
                  } else {
                    setOpenMenu(true);
                  }
                }}
                onChange={(val) => {
                  onChange(val.value);
                }}
                menuIsOpen={openMenu}
                openMenuOnClick={false}
                openMenuOnFocus={false}
              />
            )}
          />

          {errors.name && (
            <span className="text-red-500 text-sm">This field is required</span>
          )}
        </div>

        <div className="flex flex-col ">
          <label className="text-black">Birth Date:</label>
          <input
            type="date"
            className="border-2 px-2 py-2 rounded-sm"
            {...register("birth_date", { required: true })}
          />
          {errors.birth_date && (
            <span className="text-red-500 text-sm">This field is required</span>
          )}
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        <button
          type="submit"
          className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
        >
          {loading ? "Loading..." : "Verify"}
        </button>
      </form>
    </>
  );
};

export default FormVerification;
