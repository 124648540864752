import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import num1 from "../../assets/Emojis/1.png";
import num2 from "../../assets/Emojis/2.png";
import num3 from "../../assets/Emojis/3.png";
import num4 from "../../assets/Emojis/4.png";
import num5 from "../../assets/Emojis/5.png";
import smiley1 from "../../assets/Emojis/smiley_1.png";
import smiley2 from "../../assets/Emojis/smiley_2.png";
import smiley3 from "../../assets/Emojis/smiley_3.png";
import smiley4 from "../../assets/Emojis/smiley_4.png";
import smiley5 from "../../assets/Emojis/smiley_5.png";

const FormSurvey = (props) => {
  const [listLike, setListLike] = useState([]);
  const [listNotLike, setListNotLike] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [tempName, setTempname] = useState("");
  const [dataPost, setDataPost] = useState([]);
  const [msg, setMsg] = useState(null);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setTimeout(() => {
      setMsg(null);
    }, 1500);
  }, [msg]);

  // useEffect(() => {
  //   console.log(dataPost);
  // }, [dataPost]);

  const [listQuestion, setListQuestion] = useState();
  useEffect(() => {
    getListSurvey();
  }, []);

  const getListSurvey = async () => {
    const response = await axios.get(CONFIG.URL + "/survey/list");
    if (response?.data?.status === "SUCCESS") {
      setListQuestion(response.data?.data);

      setListLike(
        response?.data?.data
          ?.filter((v) => v.question.toLowerCase().includes("like"))
          .map((v) => {
            return {
              ...v,
              question: v.question.toLowerCase().replace("like", ""),
            };
          })
      );
      setListNotLike(
        response?.data?.data
          ?.filter((v) => v.question.toLowerCase().includes("not"))
          .map((v) => {
            return {
              ...v,
              question: v.question.toLowerCase().replace("not", ""),
            };
          })
      );
    }
  };

  // const listQuestion = [
  //   {
  //     id: 1,
  //     type: "number",
  //     question: "How would you rate your overall event experience?",
  //   },
  // ];

  const chooseAnswer = (data) => {
    let temp = [...dataPost];

    let exist = temp.find((v) => v.id_survey === data.id_survey);
    if (exist) {
      exist.score = data.score;
    } else {
      temp.push(data);
    }

    setDataPost(temp);
  };

  const onSubmit = (data) => {
    // props.onSuccess();
    // return;

    if (loading) return;

    const json_score = {
      json_score: dataPost,
    };

    setLoading(true);
    const payload = {
      json: JSON.stringify(json_score),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/survey/score", form_data)
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
            setMsg({
              success: false,
              msg: "Failed Submit Survey",
            });
          }
        })
        .catch(function (error) {
          setLoading(false);
          setMsg({
            success: false,
            msg: "Failed Submit Survey",
          });
        });
    } catch (error) {
      setLoading(false);
      setMsg({
        success: false,
        msg: "Failed Submit Survey",
      });
    }
  };

  // const renderSmiley = (id) => {
  //   return (
  //     <div className="flex space-x-5 mt-3">
  //       <img
  //         onClick={() => chooseAnswer({ id_survey: id, score: "1" })}
  //         src={smiley1}
  //         className={`${
  //           checkActiveAnswers(id, "1") ? "grayscale-0" : "grayscale"
  //         } hover:grayscale-0 w-8 h-8 object-contain cursor-pointer`}
  //         alt=""
  //       />
  //       <img
  //         onClick={() => chooseAnswer({ id_survey: id, score: "2" })}
  //         src={smiley2}
  //         className={`${
  //           checkActiveAnswers(id, "2") ? "grayscale-0" : "grayscale"
  //         } hover:grayscale-0 w-8 h-8 object-contain cursor-pointer`}
  //         alt=""
  //       />
  //       <img
  //         onClick={() => chooseAnswer({ id_survey: id, score: 3 })}
  //         src={smiley3}
  //         className={`${
  //           checkActiveAnswers(id, 3) ? "grayscale-0" : "grayscale"
  //         } hover:grayscale-0 w-8 h-8 object-contain cursor-pointer`}
  //         alt=""
  //       />
  //       <img
  //         onClick={() => chooseAnswer({ id_survey: id, score: 4 })}
  //         src={smiley4}
  //         className={`${
  //           checkActiveAnswers(id, 4) ? "grayscale-0" : "grayscale"
  //         } hover:grayscale-0 w-8 h-8 object-contain cursor-pointer`}
  //         alt=""
  //       />
  //       <img
  //         onClick={() => chooseAnswer({ id_survey: id, score: 5 })}
  //         src={smiley5}
  //         className={`${
  //           checkActiveAnswers(id, 5) ? "grayscale-0" : "grayscale"
  //         } hover:grayscale-0 w-8 h-8 object-contain cursor-pointer`}
  //         alt=""
  //       />
  //     </div>
  //   );
  // };

  const renderNumber = (id) => {
    return (
      <div className="flex space-x-3 mt-3">
        <div className="group flex flex-col justify-start items-center space-y-1">
          <img
            onClick={() => chooseAnswer({ id_survey: id, score: "1" })}
            src={num1}
            className={`${
              checkActiveAnswers(id, "1") ? "grayscale-0" : "grayscale"
            } group-hover:grayscale-0 w-8 h-8 object-contain cursor-pointer`}
            alt=""
          />
          <span
            className={`${
              checkActiveAnswers(id, "1") ? "text-green-500" : "text-gray-500"
            } font-bold text-xs uppercase text-center group-hover:text-green-500`}
          >
            very poor
          </span>
        </div>

        <div className="group flex flex-col justify-start items-center space-y-1">
          <img
            onClick={() => chooseAnswer({ id_survey: id, score: "2" })}
            src={num2}
            className={`${
              checkActiveAnswers(id, "2") ? "grayscale-0" : "grayscale"
            } group-hover:grayscale-0 w-8 h-8 object-contain cursor-pointer`}
            alt=""
          />
          <span
            className={`${
              checkActiveAnswers(id, "2") ? "text-green-500" : "text-gray-500"
            } font-bold text-xs uppercase text-center group-hover:text-green-500`}
          >
            POOR
          </span>
        </div>

        <div className="group flex flex-col justify-start items-center space-y-1">
          <img
            onClick={() => chooseAnswer({ id_survey: id, score: "3" })}
            src={num3}
            className={`${
              checkActiveAnswers(id, "3") ? "grayscale-0" : "grayscale"
            } group-hover:grayscale-0 w-8 h-8 object-contain cursor-pointer`}
            alt=""
          />
          <span
            className={`${
              checkActiveAnswers(id, "3") ? "text-green-500" : "text-gray-500"
            } font-bold text-xs uppercase text-center group-hover:text-green-500`}
          >
            average
          </span>
        </div>

        <div className="group flex flex-col justify-start items-center space-y-1">
          <img
            onClick={() => chooseAnswer({ id_survey: id, score: "4" })}
            src={num4}
            className={`${
              checkActiveAnswers(id, "4") ? "grayscale-0" : "grayscale"
            } group-hover:grayscale-0 w-8 h-8 object-contain cursor-pointer`}
            alt=""
          />
          <span
            className={`${
              checkActiveAnswers(id, "4") ? "text-green-500" : "text-gray-500"
            } font-bold text-xs uppercase text-center group-hover:text-green-500`}
          >
            good
          </span>
        </div>

        <div className="group flex flex-col justify-start items-center space-y-1">
          <img
            onClick={() => chooseAnswer({ id_survey: id, score: "5" })}
            src={num5}
            className={`${
              checkActiveAnswers(id, "5") ? "grayscale-0" : "grayscale"
            } group-hover:grayscale-0 w-8 h-8 object-contain cursor-pointer`}
            alt=""
          />
          <span
            className={`${
              checkActiveAnswers(id, "5") ? "text-green-500" : "text-gray-500"
            } font-bold text-xs uppercase text-center group-hover:text-green-500`}
          >
            excellent
          </span>
        </div>
      </div>
    );
  };

  const checkActive = (id) => {
    let exist = dataPost.find((v) => v.id_survey === id);
    if (exist) {
      return true;
    } else {
      return false;
    }
  };

  const checkActiveToogle = (id) => {
    let exist = dataPost.find((v) => v.id_survey === id);
    if (exist) {
      if (exist.score === "0") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkActiveAnswers = (id, score) => {
    let exist = dataPost.find((v) => v.id_survey === id);
    if (exist) {
      if (exist.score === score) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // const canSubmit = () => {
  //   if (dataPost?.length === listQuestion?.length) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const canSubmit = () => {
    let exist = dataPost.find((v) => v.id_survey === "1");
    if (exist) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="space-y-4 px-3 py-3 bg-white rounded-md w-full md:w-3/4">
        <div className="text-center font-bold mb-5">
          <div className="text-2xl uppercase">QUESTIONAIRE</div>
        </div>

        {msg !== null && msg?.success && (
          <>
            <div className="text-center font-bold mb-8">
              <div className="text-2xl mt-5">Hi, {tempName}</div>
            </div>

            <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
              SUBMIT SURVEY SUCCESS
            </div>
          </>
        )}

        {msg !== null && !msg?.success && (
          <div className="text-center font-bold text-white bg-red-500 py-2 rounded">
            {msg?.msg}
          </div>
        )}

        {(msg === null || !msg?.success) && (
          <>
            {listQuestion?.length > 0 && (
              <div className="grid grid-cols-1 md:grid-cols-1 gap-1">
                <div
                  className={`${
                    checkActive(listQuestion[0].id)
                      ? "border-green-500 border-2"
                      : "border-zync-300  border-2"
                  } hover:border-green-500 flex flex-col justify-start items-center border rounded-md py-4`}
                >
                  <div className="font-bold text-center text-2xl mb-2">
                    {listQuestion[0].question}
                  </div>
                  {listQuestion[0].type === "number"
                    ? renderNumber(listQuestion[0].id)
                    : renderNumber(listQuestion[0].id)}
                </div>
              </div>
            )}

            {listLike?.length > 0 && (
              <div className="py-1">
                <div className="font-bold text-center text-2xl mb-3">
                  What did you like?
                </div>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                  {listLike.map((item, key) => {
                    return (
                      <div
                        onClick={() =>
                          chooseAnswer({
                            id_survey: item.id,
                            score: checkActiveToogle(item.id) ? "0" : "1",
                          })
                        }
                        key={key}
                        className={`${
                          checkActiveToogle(item.id)
                            ? "bg-green-500 border-2 border-green-800 text-white"
                            : "bg-white border-2 border-black text-black"
                        }  capitalize font-bold hover:border-green-500 cursor-pointer px-3 py-2 flex justify-center items-center text-base text-center rounded-md`}
                      >
                        {item.question}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {listNotLike?.length > 0 && (
              <div className="py-1">
                <div className="font-bold text-center text-2xl mb-3">
                  What did you not like?
                </div>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                  {listNotLike.map((item, key) => {
                    return (
                      <div
                        onClick={() =>
                          chooseAnswer({
                            id_survey: item.id,
                            score: checkActiveToogle(item.id) ? "0" : "1",
                          })
                        }
                        key={key}
                        className={`${
                          checkActiveToogle(item.id)
                            ? "bg-green-500 border-2 border-green-800 text-white"
                            : "bg-white border-2 border-black text-black"
                        }  capitalize font-bold hover:border-green-500 cursor-pointer px-3 py-2 flex justify-center items-center text-base text-center rounded-md`}
                      >
                        {item.question}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="flex space-x-5 w-full justify-center">
              {canSubmit() ? (
                <button
                  type="button"
                  onClick={() => onSubmit()}
                  className={`w-max min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              ) : (
                <button
                  type="button"
                  className={`cursor-not-allowed w-max min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-gray-500  w-full text-center`}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FormSurvey;
