import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";

const FormRSVP = (props) => {
  const [loading, setLoading] = useState(false);
  const [attend, setAttend] = useState(null);
  const [guest, setGuest] = useState(null);

  const [msg, setMsg] = useState("");
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (attend !== null) {
      setMsg("");
    }
  }, [attend]);

  useEffect(() => {
    if (guest !== null) {
      setMsg("");
    }
  }, [guest]);

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
    }

    return isValid;
  };

  const onSubmit = (data) => {
    // console.log(data);
    // return;
    if (attend === null) {
      setMsg("Please choose attending option");
      return;
    }

    if (attend === true && guest === null) {
      setMsg("Please choose bringing a guest option");
      return;
    }

    if (data.email !== data.confirm_email) {
      setMsg("Email address does not match");
      return;
    }

    if (loading) return;

    setLoading(true);
    setMsg("");
    const payload = {
      email: data.email,
      guest: guest ? 1 : 0,
    };

    if (guest && data.payment !== null && data.payment?.length > 0) {
      let fileSizeMB = data.payment[0].size / 1024 ** 2;
      if (fileSizeMB > 2) {
        setMsg("Maximum file size to upload is 2MB");
        setLoading(false);
        return;
      }

      let arrname = data.payment[0].name.split(".");
      let ext = arrname[arrname.length - 1];
      if (
        ext.toLowerCase() !== "jpg" &&
        ext.toLowerCase() !== "jpeg" &&
        ext.toLowerCase() !== "png"
      ) {
        setMsg("Extension file to upload is .jpg, jpeg, .png");
        setLoading(false);
        return;
      }

      payload.payment = data.payment[0];
    }

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/user/rsvp", form_data, {
          headers: {
            token: props?.user?.token,
          },
        })
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-1/2 transition-all"
      >
        <div className="text-center font-bold mb-8">
          <div className="text-2xl mt-5">
            Hi, {props.user?.first_name}{" "}
            {props.user?.last_name !== "" ? " " + props.user?.last_name : ""}
          </div>
          <div className="text-base font-normal">
            Birth Date :{" "}
            {moment(props.user?.birth_date).format("MMMM, DD YYYY")}
          </div>
          <div className="text-base font-normal">
            Department : {props.user?.departement}
          </div>
        </div>

        <div className="flex flex-col">
          <div>
            <div className="text-center text-xl">
              Would you be attending the End of Year Staff Event?
            </div>
            <div className="flex justify-center space-x-5 mt-3">
              <button
                onClick={() => setAttend(true)}
                type="button"
                className={`${
                  attend === null
                    ? "bg-gray-500"
                    : attend
                    ? "bg-blue-500"
                    : "bg-gray-500"
                } w-[100px]  py-2 rounded-lg text-white font-bold text-center`}
              >
                YES
              </button>
              <button
                onClick={() => setAttend(false)}
                type="button"
                className={`${
                  attend === null
                    ? "bg-gray-500"
                    : attend
                    ? "bg-gray-500"
                    : "bg-blue-500  "
                }  w-[100px]  py-2 rounded-lg text-white font-boldtext-center`}
              >
                No
              </button>
            </div>
          </div>

          {attend && (
            <>
              <hr className="my-3" />

              <div className="">
                <div className="text-center text-xl">
                  Would you be bringing a guest?
                </div>
                <div className="flex justify-center space-x-5 mt-3">
                  <button
                    onClick={() => setGuest(true)}
                    type="button"
                    className={`${
                      guest === null
                        ? "bg-gray-500"
                        : guest
                        ? "bg-blue-500"
                        : "bg-gray-500"
                    } w-[100px]  py-2 rounded-lg text-white font-bold text-center`}
                  >
                    YES
                  </button>
                  <button
                    onClick={() => setGuest(false)}
                    type="button"
                    className={`${
                      guest === null
                        ? "bg-gray-500"
                        : guest
                        ? "bg-gray-500"
                        : "bg-blue-500  "
                    }  w-[100px]  py-2 rounded-lg text-white font-boldtext-center`}
                  >
                    No
                  </button>
                </div>
              </div>

              {guest && (
                <>
                  <hr className="my-3" />

                  <div className="flex flex-col mx-auto justify-center items-center w-full">
                    <label className="text-black w-full text-center text-xl">
                      Upload proof of payment for guest
                    </label>
                    <input
                      accept=".jpeg, .jpg, .png"
                      type="file"
                      autoComplete="off"
                      role="presentation"
                      className="border-2 px-2 py-2 rounded-sm w-[340px] text-center"
                      {...register("payment", {
                        required: true,
                      })}
                    />
                    {errors.payment && (
                      <span className="text-red-500 text-sm">
                        This field is required
                      </span>
                    )}
                  </div>
                </>
              )}

              <hr className="my-3" />

              <div className="flex flex-col mx-auto justify-center items-center w-full">
                <label className="text-black w-full text-center text-xl">
                  Enter Email address
                </label>
                <input
                  autoComplete="off"
                  role="presentation"
                  className="border-2 px-2 py-2 rounded-sm w-[340px] text-center"
                  onInput={(e) => {
                    setValue("email", e.target.value.trim().toLowerCase());
                  }}
                  {...register("email", {
                    required: true,
                    validate: handleEmailValidation,
                  })}
                />
                {errors.email && (
                  <>
                    {errors.email?.type === "validate" ? (
                      <span className="text-red-500 text-sm">
                        Email not Valid
                      </span>
                    ) : (
                      <span className="text-red-500 text-sm">
                        This field is required
                      </span>
                    )}
                  </>
                )}
              </div>

              <div className="flex flex-col mt-5 mx-auto justify-center items-center w-full">
                <label className="text-black w-full text-center text-xl">
                  Confirm Email address (must match above)
                </label>
                <input
                  autoComplete="off"
                  role="presentation"
                  className="border-2 px-2 py-2 rounded-sm w-[340px] text-center"
                  onInput={(e) => {
                    setValue(
                      "confirm_email",
                      e.target.value.trim().toLowerCase()
                    );
                  }}
                  {...register("confirm_email", {
                    required: true,
                    validate: handleEmailValidation,
                  })}
                />
                {errors.confirm_email && (
                  <>
                    {errors.confirm_email?.type === "validate" ? (
                      <span className="text-red-500 text-sm">
                        Email not Valid
                      </span>
                    ) : (
                      <span className="text-red-500 text-sm">
                        This field is required
                      </span>
                    )}
                  </>
                )}
              </div>

              <hr className="mt-5" />
            </>
          )}

          {(attend === null || attend === false) && (
            <div className="mt-5"></div>
          )}
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        <div className="mx-auto flex justify-center items-center">
          <button
            type="submit"
            className={`w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
          >
            {loading ? "Loading..." : "SUBMIT"}
          </button>
        </div>
      </form>
    </>
  );
};

export default FormRSVP;
