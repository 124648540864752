import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";

import FormVerification from "./survey.from";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import FormSurvey from "./survey.from";
import FormThankyou from "./thankyou.from";
import helper from "../../utils/helper";

const PageSurvey = (props) => {
  const [options, setOptions] = useState([]);
  const [state, setState] = useState("survey");

  

  useEffect(() => {
    // if (Cookies.get("token") === undefined) {
    //   window.location.href = "./home";
    // }
  }, []);

  const handleSuccesSurvey = (data) => {
    setState("thankyou");

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <>
      {
        <div
          className="w-full min-h-screen flex flex-col justify-start items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url('${helper.getBackground(
              props?.setting?.background
            )}')`,
          }}
        >
          <div className="flex flex-col justify-center items-center py-5 w-full">
            <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
              {/* VERIFICATION */}
              {state === "survey" && (
                <FormSurvey
                  onSuccess={() => handleSuccesSurvey()}
                  options={options}
                />
              )}

              {/* VERIFICATION */}
              {state === "thankyou" && <FormThankyou />}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PageSurvey;
