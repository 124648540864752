import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";

const FormExist = (props) => {
  return (
    <>
      <div className="space-y-5 px-5 py-10 bg-white rounded-md w-full md:w-1/2 transition-all">
        <div className="text-center font-bold mb-8">
          <div className="text-2xl mt-5">
            Hi, {props.user?.first_name}{" "}
            {props.user?.last_name !== "" ? " " + props.user?.last_name : ""}
          </div>
          <div className="text-base font-normal">
            Birth Date :{" "}
            {moment(props.user?.birth_date).format("MMMM, DD YYYY")}
          </div>
          <div className="text-base font-normal">
            Department : {props.user?.departement}
          </div>
        </div>

        <div className="flex flex-col">
          <div className="text-center text-xl font-bold">
            You have already submitted your registration.
          </div>
        </div>
        <div className="flex flex-col w-full justify-center items-center text-center">
          <div className="text-base font-normal text-center mt-3">
            Please check your spam/junk email folders
          </div>
          <div className="text-base font-normal text-center">
            if you do not see the email in your primary inbox.
          </div>
        </div>

        <div className="mx-auto flex flex-col justify-center items-center">
          <div className="text-base text-center font-bold mt-5">
            If you want to change data, please <span onClick={() => props.onChangeData()} className="underline text-green-500 cursor-pointer">click here</span>
          </div>
          {/* <button
            type="button"
            onClick={() => props.ok()}
            className={`mt-10 w-[140px]  px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
          >
            OK
          </button> */}
        </div>
      </div>
    </>
  );
};

export default FormExist;
