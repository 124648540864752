import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";

import FormVerification from "./exit.form";
import FormExit from "./exit.form";
import helper from "../../utils/helper";

const PageExit = (props) => {
  const [options, setOptions] = useState([]);
  const [user, setUser] = useState(null);
  const [state, setState] = useState("exit");

  useEffect(() => {
    getLitsUser();
  }, []);


  const getLitsUser = async () => {
    const response = await axios.get(CONFIG.URL + "/user/list?limit=1000000");

    if (response?.data?.status === "SUCCESS") {
      setOptions(
        response.data?.data?.map((item) => {
          return {
            ...item,
            label: item.first_name + " " + item.last_name,
            value: item.id,
          };
        })
      );
    }
  };

  const handleSuccesverification = (data) => {
    setUser(data);

    if (data.is_rsvp === "1") {
      // setState("exist");
      setState("rsvp-edit");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
    } else {
      setState("rsvp");
    }
  };


  return (
    <>
      {
        <div
          className="w-full min-h-screen flex flex-col justify-start items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url('${helper.getBackground(
              props?.setting?.background
            )}')`,
          }}
        >
          <div className="flex flex-col justify-center items-center py-10 w-full">
            <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
              {/* VERIFICATION */}
              {state === "exit" && (
                <FormExit
                  onSuccess={(data) => handleSuccesverification(data)}
                  options={options}
                />
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PageExit;
