import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import FormVerification from "./verification.from";
import FormRSVP from "./rsvp.from";
import FormThankyou from "./thankyou.from";
import FormExist from "./exist.from";
import FormRSVPEdit from "./rsvp-edit.from";

const PageRSVP = (props) => {
  const [options, setOptions] = useState([]);
  const [user, setUser] = useState(null);
  const [state, setState] = useState("verification");
  const [config, setConfig] = useState(null);

  useEffect(() => {
    getLitsUser();
  }, []);

  const getConfig = async () => {
    try {
      const response = await axios.get(CONFIG.URL + "/config");

      if (response?.data?.status === "SUCCESS") {
        setConfig(response.data.config);
      }
    } catch (error) {}
  };

  const getLitsUser = async () => {
    const response = await axios.get(CONFIG.URL + "/user/list?limit=1000000");

    if (response?.data?.status === "SUCCESS") {
      setOptions(
        response.data?.data?.map((item) => {
          return {
            ...item,
            label: item.first_name + " " + item.last_name,
            value: item.id,
          };
        })
      );
    }
  };

  const handleSuccesverification = (data) => {
    setUser(data);

    if (data.is_rsvp === "1") {
      // setState("exist");
      setState("rsvp-edit");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
    } else {
      setState("rsvp");
    }
  };

  const handleChangeData = () => {
    setState("rsvp-edit");
  };

  const handleSuccesRSVP = (data) => {
    setState("thankyou");

    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);
  };

  const handleResetForm = () => {
    window.location.reload();
  };

  if (props?.setting?.rsvp_status === "disable") {
    return (
      <>
        <div
          className="w-full min-h-screen flex flex-col justify-center items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url('${props?.setting?.background}')`,
          }}
        >
          <div className="flex flex-col justify-center items-center w-full">
            <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
              <div className="space-y-5 px-5 py-10 bg-white rounded-md w-full max-w-lg items-center flex flex-col">
                <div className="text-center font-bold text-2xl ">
                  First Citizens End of Year Staff Party
                </div>

                <div className="text-center font-bold text-xl rounded-md  bg-red-500 px-5 py-2 text-white">
                  RSVP Is Closed
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (
    (options === null || options?.length === 0) &&
    props?.setting?.rsvp_status === "disable"
  ) {
    return (
      <div className="flex flex-col absolute top-0 left-0 w-screen min-h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
        Loading...
      </div>
    );
  }

  return (
    <>
      {props?.setting?.rsvp_status === "enable" && (
        <div
          className="w-full min-h-screen flex flex-col justify-start items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url('${props?.setting?.background}')`,
          }}
        >
          <div className="flex flex-col justify-center items-center py-20 w-full">
            <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
              {/* VERIFICATION */}
              {state === "verification" && (
                <FormVerification
                  onSuccess={(data) => handleSuccesverification(data)}
                  options={options}
                />
              )}

              {/* RSVP */}
              {state === "rsvp" && (
                <FormRSVP user={user} onSuccess={() => handleSuccesRSVP()} />
              )}

              {/* RSVP CHANGE */}
              {state === "rsvp-edit" && (
                <FormRSVPEdit
                  user={user}
                  onSuccess={() => handleSuccesRSVP()}
                />
              )}

              {/* THANK YOU */}
              {state === "thankyou" && (
                <FormThankyou user={user} ok={() => handleResetForm()} />
              )}

              {/* EXIST */}
              {state === "exist" && (
                <FormExist
                  user={user}
                  onChangeData={() => handleChangeData()}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PageRSVP;
