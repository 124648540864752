import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import useReport from "../../hook/useReport";
import moment from "moment";

const FormVerification = (props) => {
  const localReport = useReport();
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [focus, setFocus] = useState("name");
  const [msg, setMsg] = useState("");
  const [count, setCount] = useState(0);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
      handleCheckinTime();
    }, 1000 * 5);

    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    localReport.fetchData();
  }, []);

  const handleCheckinTime = async () => {
    const response = await axios.get(
      CONFIG.URL + "/event/report/currentcheckin"
    );

    let localTime = Cookies.get("local_time");
    if (localTime && localTime !== undefined && localTime !== "undefined") {
      if (moment(response?.data?.last_update) > moment(localTime)) {
        localReport.fetchData();
        Cookies.set("local_time", response?.data?.last_update);
      }
    } else {
      Cookies.set("local_time", response?.data?.last_update);
      localReport.fetchData();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setMsg(null);
    }, 1500);
  }, [msg]);

  const onSubmit = (data) => {
    if (focus === "name") {
      checkName(data);
    } else if (focus === "rfid") {
      checkRFID(data);
    } else if (focus === "qr_code") {
      checkQRCode(data);
    }
    return;
  };

  const checkRFID = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    try {
      axios
        .get(CONFIG.URL + "/event/rfid/" + data.rfid, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess(response?.data?.data[0], "rfid");
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const checkQRCode = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    try {
      axios
        .get(CONFIG.URL + "/user/" + data.qr_code, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess(response?.data?.data[0], "qr_code", data.qr_code);
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const checkName = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    try {
      axios
        .get(CONFIG.URL + "/user/" + data.name, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess(response?.data?.data[0], "name");
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const resetForm = () => {
    setValue("name", "");
    setValue("rfid", "");
    setValue("qr_code", "");
    reset();
  };

  const calcCheckin = () => {
    if (localReport?.data) {
      const { checkin, checkin_guest, exit, reentry } = localReport?.data;
      return checkin + checkin_guest - exit + reentry;
    }

    return 0;
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-3/4"
      >
        <div className="text-center font-bold mb-10">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="text-2xl">Main Gate</div>
          <div className="text-black font-semibold text-xl">
            Currently Checked In: {calcCheckin() || 0}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-10">
          

          {/* SCAN QR CODE */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">
                Scan QR CODE
              </label>
              <button
                type="button"
                onClick={() => resetForm()}
                className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
              >
                Clear
              </button>
            </div>

            <input
            autoFocus
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("qr_code")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("qr_code", { required: false })}
            />
          </div>

          {/* SEARCH NAME */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">Name</label>
            </div>

            <Controller
              control={control}
              name="name"
              rules={{ required: false }}
              render={({ field: { onChange, value, ref, name } }) => (
                <ReactSelect
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  
                  isClearable
                  dropdown
                  onFocus={() => setFocus("name")}
                  className={"react-select"}
                  classNamePrefix={"react-select"}
                  placeholder={"Search your name"}
                  options={props.options}
                  onInputChange={(val) => {
                    if (val.length <= 2) {
                      setOpenMenu(false);
                    } else {
                      setOpenMenu(true);
                    }
                  }}
                  onChange={(val) => {
                    onChange(val?.value);
                  }}
                  menuIsOpen={openMenu}
                  openMenuOnClick={false}
                  openMenuOnFocus={false}
                />
              )}
            />
            <span className="text-xs text-gray-500 mt-1">
              *type at least three characters
            </span>
          </div>

          {/* SCAN RFID */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">Scan RFID</label>
              <button
                type="button"
                onClick={() => resetForm()}
                className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
              >
                Clear
              </button>
            </div>

            <input
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("rfid")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("rfid", { required: false })}
            />
          </div>
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        {focus === "name" && (
          <button
            type="submit"
            className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
          >
            {loading ? "Loading..." : "Submit Check Name"}
          </button>
        )}

        {focus === "rfid" && (
          <button
            type="submit"
            className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}
          >
            {loading ? "Loading..." : "Submit Check RFID"}
          </button>
        )}

        {focus === "qr_code" && (
          <button
            type="submit"
            className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-purple-500  w-full text-center`}
          >
            {loading ? "Loading..." : "Submit Check QR CODE"}
          </button>
        )}

        <div className="w-full flex justify-center items-center">
          <a
            href="./add-user"
            className={`max-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-amber-500  w-full text-center`}
          >
            Add User
          </a>
        </div>
      </form>
    </>
  );
};

export default FormVerification;
